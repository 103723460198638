import React from "react";
import { Grid ,Typography} from "@mui/material";
import  './contact_us.css'
import D6_DASAC_Form from "./contact_form/D6_DASAC_Form";
import Helmet from "react-helmet";

import image from'../../images/Filly4.jpg'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
export default function ContactUs(props) {
  const ContactData=props.contactData

  if(ContactData&&ContactData[0]){
    return (
      <>




<Helmet>
        {/* <!-- HTML Meta Tags --> */}
 
        <title>Contact Us | FillyMedia</title>
        {ContactData.filter((val)=>val.category==='meta-description').sort((a,b)=>a.id<b.id?-1:1).map((valB,idx)=>{
  return(
        <meta
          name="description"
          content={valB.value}
        />)})} 

{ContactData.filter((val)=>val.category==='meta-keywords').sort((a,b)=>a.id<b.id?-1:1).map((valB,idx)=>{
  return(
    <meta name="keywords" content={valB.value} />
    )})} 

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Contact Us | FillyMedia" />
        {ContactData.filter((val)=>val.category==='meta-description').sort((a,b)=>a.id<b.id?-1:1).map((valB,idx)=>{
  return(
    <meta
    itemprop="description"
    content={valB.value}
  />)})} 
       
        
    

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://fillynedia.com/contactus" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Contact Us | FillyMedia" />
        {ContactData.filter((val)=>val.category==='meta-description').sort((a,b)=>a.id<b.id?-1:1).map((valB,idx)=>{
  return(
    <meta
          property="og:description"
          content={valB.value}
        />)})} 
        
      
      

      
       
      </Helmet>
{ContactData.filter((val)=>val.category==='BackgroundImageContact').sort((a,b)=>a.id<b.id?-1:1).map((valB,idx)=>{
  return(
    
      <div className="bg_img" style={{backgroundImage:`linear-gradient(rgb(99,192,224,0.7),rgb(0,0,0,0.5)),url(${(valB.value)})` }}>
          <Grid container className="grid_container" >

         {ContactData.filter((val)=>val.category==='BackgroundImageHeading').sort((a,b)=>a.id<b.id?-1:1).map((val1,idx)=>{
  return(     <Grid item sm={6} xs={12}>
                  <div className="heading_contact" >
                 {val1.value}
                  </div>
                
                  <ul className="ulComm1">
                  {ContactData.filter((val)=>(val.category===('BackgroundImageText')&&val.parent.toString().includes(valB.id))).sort((a,b)=>a.id<b.id?-1:1).map((val,idx)=>{
  return(
          <li
              component="p"
              className="D6_ADS_G1_DivPComm_B1"
            >
{val.value}              </li>
)})} 
         
          
        
        
              </ul>
              {/* <div  className="image_below">

              </div> */}
              </Grid>  )})}   
              <Grid item  className="grid_form" sm={6} xs={12}>
                 <D6_DASAC_Form /> 
              </Grid>

          </Grid>
          </div>
 )})}   
   </>
  )

  }
  else{
    return(
    <Box sx={{ display: 'flex' }} className="spinner">
    <CircularProgress style={{width:70,height:70}} />
  </Box>)
  }
  }