
import Card from '@mui/material/Card';

import {CardActionArea, CardContent, CardMedia, Container,Grid, Typography} from "@mui/material";
import './about_us.css'
// import { HomeData } from '../homepageData';

export const About_Us=(props)=>{
  const {HomeData,eventData}=props
    return(
        <>
        <Container style={{marginBottom:'20px'}}>
        <Grid container sm={12} xs={12}>
  <Grid item sm={12} xs={12}>
    <Typography style={{paddingBottom:10}} className='head_WCU'>
UPCOMING EVENTS
    </Typography>
  </Grid>
{eventData.filter((val,idx)=>val.active===true).sort((a,b)=>a.id>b.id?1:-1).map((val1,idx)=>
  <Grid key={idx}  item lg={6} sm={12} md={6} xs={12} className="Dec6_Industries_B2B_Grid1"  >
    <Container className="Dec6_Industries_B2B_Cont Dec6_Industries_B2B_Cont1">
    <Card sx={{ maxWidth: '100%' }}>
<CardActionArea>
<CardMedia
component="img"
height="240"
image={val1.image}
alt="green iguana"
/>
<CardContent className='card_color'>

< Typography  gutterBottom className='card_typE' variant="h5" component="div">
{val1.title}
</Typography>

<Typography  className='card_text1E'  variant="body2" color="text.secondary">
Date: {val1.date}
</Typography><br/>


<Typography  className='card_text2E'  variant="body2" color="text.secondary">
{val1.description}</Typography>

</CardContent>
</CardActionArea>
</Card>
    </Container>
</Grid>)  }

</Grid>
        <Grid container justifyContent={'center'}     >



        {HomeData.filter((val)=>val.category.includes('AboutUsImg')).sort((a,b)=>a.id<b.id?-1:1).map((val1,idx)=>{
  return(

    <Grid key={idx} item lg={6} sm={12} md={6} xs={12} className="Dec6_Industries_B2B_Grid1"  >
    <Container className="Dec6_Industries_B2B_Cont Dec6_Industries_B2B_Cont1">
    <Card sx={{ maxWidth: 'auto',borderRadius:5 }}>

<div  style={{backgroundImage:`linear-gradient(rgb(99,192,214,0.7),rgb(0,0,0,0.6)),url(${val1.value})`,backgroundSize:'cover',backgroundPosition:'bottom',backgroundRepeat:"no-repeat"}} className='grid_au'>

{HomeData.filter((val)=>(val.category.includes('AboutUsHead')&&val.parent.includes(val1.id))).sort((a,b)=>a.id<b.id?-1:1).map((val,idx)=>{
  return(
    <div key={idx} className='text_grid_au'> 
    {val.value}
    </div>
)})}  
{HomeData.filter((val)=>(val.category.includes('AboutUsSubHead')&&val.parent.includes(val1.id))).sort((a,b)=>a.id<b.id?-1:1).map((val,idx)=>{
  return(
    <div key={idx} className='para_text_grid'>
        {`${val.value}`}
</div>
)})}  



</div>
</Card>
    </Container>
</Grid>

)})}   
        
   
        
                        
                    </Grid>
                 
        </Container>
        </>
    )
}