import React, { useState,useEffect } from 'react';
import styled from 'styled-components';
import { FiPlus, FiMinus } from 'react-icons/fi';

import {Typography,TextField,Grid,Card,CardActionArea,CardMedia,Chip, FormGroup, FormControlLabel, Switch,} from "@mui/material";
import { IconContext } from 'react-icons';
import { Alert, AlertTitle } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

import './admin.css'
import { apilink } from '../../App';



const AccordionSection = styled.div`
margin-top:30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
/*  position: relative;  */
  height: 170vh;
  height: 100%;
 /* background: #fff;  */
  background: white;  
  z-index: 4;
  /*
  @media (max-width: 1200px) {
        height: 180vh ;
  }
  @media (max-width: 750px) {
        height: 110vh ;
  }
  @media (max-width: 660px) {
        height: 120vh ;
  }
  @media (max-width: 469px) {
        height: 135vh ;
  }
  @media (max-width: 430px) {
        height: 146vh ;
  }
  @media (max-width: 400px) {
        height: 154vh ;
  }
  @media (max-width: 350px) {
        height: 167vh ;
  }  */
`;

const Container = styled.div`
/*  position: absolute; */
  width: 100%;
  top: 30%;
/*  box-shadow: 2px 10px 35px 1px rgba(153, 153, 153, 0.3);
  box-shadow: 2px 10px 35px 1px rgba(153, 153, 153, 0.3);*/
`;

const Wrap = styled.div`
  background: #272727;
  background: white;
  color: #fff;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  cursor: pointer;
  h1 {
    padding: 2rem;
    font-size: 1.3rem;
    text-align: left;
  }
  span {
    margin-right: 1.5rem;
  }
  @media (max-width: 750px) {
    h1 {
    font-size: 1.05rem;
    padding: 1rem;
    }
  }
  
`;

const Dropdown = styled.div`
  background: #1c1c1c;
  background: white;
  color: white;
  color: black;
  width: 100%;
  height: 130px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
 /* align-items: center; */
  border-bottom: 1px solid white;
  border-bottom: 1px solid var(--redcolor);
  border-top: 1px solid white;
  border-top: 1px solid var(--redcolor);
  p {
    padding: 2rem;
    padding: 0.5rem 2rem;
    font-size: 1.1rem;
  }
  @media (max-width: 750px) {
    p {
    font-size: 0.9rem;
    padding: 0.2rem 1rem;
    }
  }
  /*
  @media (max-width: 1200px) {
        height: 160px ;
    p {
    padding: 0rem 1rem;
    font-size: 1rem;
    }
  }
  @media (max-width: 750px) {
        height: 190px ;
  }
  @media (max-width: 652px) {
        height: 210px ;
  }
  @media (max-width: 600px) {
        height: 240px ;
  }
  @media (max-width: 540px) {
        height: 270px ;
  }
  @media (max-width: 480px) {
        height: 300px ;
  }
  @media (max-width: 440px) {
        height: 350px ;
  }
  @media (max-width: 400px) {
        height: 400px ;
  }
  @media (max-width: 357px) {
        height: 470px ;
  }   */
`;
export default function EventsAdmin(props) {

    const { ContactData,eventData }=props

    const [clicked, setClicked] = useState(false);
    const [open,setOpen]=useState(false)
    const [open1,setOpen1]=useState(false)
    const [error,setError]=useState('')
    const [image,setImage]=useState('')
    const [edit,setEdit]=useState('')
    const [text,setText]=useState('')
    const [newV,setNewV]=useState(false)
    const [newV1,setNewV1]=useState(false)
    const[active,setActive]=useState('')
    const [processing,setProcessing]=useState(false)
    const [newEvent,setNewEvent]=useState(false)
    const [data,setData]=useState('')
    const [checked,setChecked]=useState(false)
    const [checkedS,setCheckedS]=useState(false)
    // console.log(edit,active)
    const ImageCover=(img,val)=>{
    
    

      if(!edit&&img){
        setActive(val.id+'img')
        setEdit(true)
        
      if(img&&img.name)
    
        if (img.name.toLowerCase().match(/\.(jpg|jpeg|png)$/))
      {
        
        if(img.size/1048576 <4){
    setImage(img)   
      }
         else{
           setError('File should be <4MB')
           setOpen(true)
         }
       } 
       else{
         setError('Upload jpg|jpeg|png format image')
         setOpen(true)
    
       }}
       if(edit&&!img&&active===(val.id+'img')){
         Save1(val)
       }
       if(edit&&!img&&active!==(val.id+'img')){
         Cancel()
       }
       if(edit&&img&&active!==(val.id+'img')){
        Cancel()
      }
    }
    const ImageCoverB=(img,val)=>{
    
    

      if(!edit&&img){
        setActive(val.id)
        setEdit(true)
        
      if(img&&img.name)
    
        if (img.name.toLowerCase().match(/\.(jpg|jpeg|png)$/))
      {
        
        if(img.size/1048576 <4){
    setImage(img)   
      }
         else{
           setError('File should be <4MB')
           setOpen(true)
         }
       } 
       else{
         setError('Upload jpg|jpeg|png format image')
         setOpen(true)
    
       }}
       if(edit&&!img&&active===(val.id)){
         Save(val)
       }
       if(edit&&!img&&active!==(val.id)){
         Cancel()
       }
       if(edit&&img&&active!==(val.id)){
        Cancel()
      }
    }
      const ImageCover1=(img)=>{
    
  
  
      
        if(img&&img.name)
      
          if (img.name.toLowerCase().match(/\.(jpg|jpeg|png)$/))
        {
          
          if(img.size/1048576 <4){
      setData({...data,image:img})  
        }
           else{
             setError('File should be <4MB')
             setOpen(true)
           }
         } 
         else{
           setError('Upload jpg|jpeg|png format image')
           setOpen(true)
      
         }
       
      }

      const Cancel=()=>{
        setActive('')
        setImage('')
        setEdit(!edit)
        setText('')
      }
      const Save=(val,val1)=>{
        setProcessing(true)
        const formData=new FormData()
        formData.append('value',val.type==='image'&&image?image:text)
        formData.append('id',val.id)
        formData.append('type',val.type)
    
    
    fetch(apilink+'contact',{
     method:'PUT',
     body:formData
      
    }).then(resp=>resp.json())  
    .then((res)=>respSave(res))  
      }


      const respSave=(val)=>{
        setError(val.detail)
    
        setEdit(false)
        setActive('')
        setImage('')
        setText('')
        setProcessing(false)
        setNewV1(false)
    setNewV(false)
    
        if(val.status){
    
          
      
    setOpen1(true)
    reloadFxn()
    }
    if(!val.status){
     
    setOpen(true)
    }
    
      }
      const reloadFxn=()=>{
        props.reloadFxn()
      }



      const NewEvent=()=>{
        setData('')
        setNewEvent(false)
      }
      const saveNewEvent=()=>{
        if(newEvent){
    if(data.image&&data.title&&data.description&&data.eventDate)
    
    
         { setProcessing(true)
          const formData=new FormData()
          formData.append('image',data.image)
          formData.append('title',data.title)
          formData.append('description',data.description)
          formData.append('eventDate',data.eventDate)
          formData.append('category','event')
//  webp,jfif,

    
    
    
    
      
      fetch(apilink+'events',{
       method:'POST',
       body:formData
        
      }).then(resp=>resp.json())  
      .then((res)=>respBlog(res))  }
      else{
        setError('All Fields are necessary')
        setOpen(true)
      }
        }
        else{
          setNewEvent(true)
        }
      }
    
      const respBlog=(val)=>{
        setError(val.detail)
        setProcessing(false)
        if(val.status){
          setNewEvent(false)
          setOpen1(true)
          reloadFxn()
    
        }
        else{
            setNewEvent(false)
    
          setOpen(true)
        }
      }
    

    const handleClose=()=>{
        setOpen(false)
        setOpen1(false)
      
      }
    const toggle = index => {
      if (clicked === index) {
        return setClicked(null);
      }
  
      setClicked(index);
    };
    const EditB=(val)=>{

   

        if(edit&&active===val.id){
         
          Save(val)
        }
        else{
          setActive(val.id)
          setText(val.value)
          setImage('')
          setEdit(!edit)
        }
    
    
      }
     
      const changeSwitch=(val,val1)=>{
        setActive(val1.id+val1.active.toString())
        setChecked(val)
        setCheckedS(true)
        setEdit(true)
        }




        const Edit=(val,val1,val2,)=>{

   

            if(edit&&active===val1){
              Save1(val)
            }
            else{
             
              setActive(val1)
              setText(val2)
              setImage('')
              setEdit(!edit)
            }
        
        
          } 
          const Save1=(val)=>{
            setProcessing(true)
            const formData=new FormData()
            formData.append(`${((val.id+val.title)===active?'title':(val.id+val.description)===active?'description':(val.id+val.date)===active?'date':(val.id+'img')===active&&image?'image':'active')}`,(val.id+'img')===active&&image?image:(val.id+val.active.toString())===active&&checked?'1':(val.id+val.active.toString())===active&&!checked?'0':text)
            formData.append('id',val.id)
        
        
        fetch(apilink+'events',{
         method:'PUT',
         body:formData
          
        }).then(resp=>resp.json())  
        .then((res)=>respSave(res))  
          }
    

  return (
   <>
       <h1 style={{marginTop:'30vh',paddingLeft:'25px'}}>
          Event Page Sections
      </h1>
      <Snackbar open={open}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert onClose={handleClose} severity="error">
        <AlertTitle>Error!!</AlertTitle>
        {error}
      </Alert>
      </Snackbar>
      <Snackbar open={open1}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert onClose={handleClose} severity="success">
        <AlertTitle>Success!!</AlertTitle>
        {error}
      </Alert>
      </Snackbar>
   <IconContext.Provider className="D6_FE_A_IC" value={{ color: 'var(--bcolor)', size: '25px' }}>
   <AccordionSection  className="D6_FE_AS">

   
<Container className="D6_FE_Cont">
         
         <>
           <Wrap className="D6_FE_Wrap" onClick={() => toggle(1)}>
             <h1 style={{color:'#000',fontWeight:'normal'}}>Background Image</h1>
             <span>{clicked === 1 ? <FiMinus /> : <FiPlus />}</span>
           </Wrap>
           {clicked === 1 ? (
             <Dropdown className="D6_FE_DD">
           {ContactData.filter((val)=>val.category.includes('BackgroundImageEvents')).sort((a,b)=>a.id<b.id?-1:1).map((val1,idx)=>{
 return(    
       <Grid  key={idx} container className="Dec6_Industries_B2B_Cont Dec6_Industries_B2B_Cont1">
   <Grid style={{textAlign:'-webkit-center'}} item sm={6} xs={12}>
 
    <Card sx={{ maxWidth: 345 }}>
<CardActionArea>
<CardMedia
component="img"
height="240"
image={image?URL.createObjectURL(image):(val1.value)}
alt="green iguana"
/>

</CardActionArea>
</Card>
 
</Grid>
<Grid style={{alignSelf:'center'}} container sm={6} xs={12}>
<Grid style={{alignSelf:'center',marginTop:'20px'}} item sm={6} xs={12}>

{/* <Chip label="Edit Background Image" style={{backgroundColor:'#52B9D1',color:'#ffff'}}  /> */}
<div onClick={()=>{ImageCoverB('',val1)}} style={{ display:'flex',justifyContent:'space-around' , backgroundColor: '#52B9D1',borderRadius:'30px',height:'40%',paddingTop:'5px',textAlign:'center',paddingBottom:'5px'}}>
                          <label  for="files1" style={{fontSize:'small',fontWeight:'bold' ,width:'100%',color: 'white',cursor:'pointer'}} >{image&&edit&&active===val1.id&&!processing?'Save':image&&edit&&active===val1.id&&processing?'Saving':'Edit Background Image'}</label>
                          </div>

                          <input type={edit?'text':"file" } id="files1" style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>ImageCoverB(e.target.files[0],val1)}/><br />
                 </Grid> 
    {edit?             <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
  
<Chip label="Cancel" onClick={()=>Cancel(val1.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px',cursor:'pointer'}}  />
</Grid>:null}
</Grid>
    </Grid >
     )})}


             </Dropdown>
           ) : null}
         </>
   
   </Container>
      
<Container className="D6_FE_Cont">
         
         <>
           <Wrap className="D6_FE_Wrap" onClick={() => toggle(2)}>
             <h1 style={{color:'#000',fontWeight:'normal'}}>Background Image Text</h1>
             <span>{clicked === 2 ? <FiMinus /> : <FiPlus />}</span>
           </Wrap>
           {clicked === 2 ? (
             <Dropdown className="D6_FE_DD">
  
             
  {ContactData.filter((val)=>val.category.includes('BackgroundImageHeadingEvents')).sort((a,b)=>a.id<b.id?-1:1).map((val,idx)=>{
      return(
        <Grid key={idx}  container className="Dec6_Industries_B2B_Cont Dec6_Industries_B2B_Cont1">
        <Grid style={{textAlign:'-webkit-center'}} item sm={6} xs={12}>
       
      {edit&&active===val.id?
       <TextField 
      id="outlined-basic" style={{width:'70%'}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />:
        <Typography>{val.value}</Typography>}
        </Grid>
        <Grid style={{alignSelf:'center'}} className='gird_chip' item sm={6} xs={12}>
        <Chip onClick={()=>EditB(val)}  label={edit&&active===val.id&&!processing?"Save":edit&&active===val.id&&processing?'Saving':'Edit Text' }style={{backgroundColor:'#52B9D1',color:'#ffff'}}  />
       {edit&&active===val.id? <Chip label="Cancel"  onClick={()=>Cancel(val.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
       :null }
        </Grid>
        </Grid >
    )})}  

             </Dropdown>
           ) : null}
         </>
   
   </Container>
      
<Container className="D6_FE_Cont">
         
         <>
           <Wrap className="D6_FE_Wrap" onClick={() => toggle(3)}>
             <h1 style={{color:'#000',fontWeight:'normal'}}>Events</h1>
             <span>{clicked === 3 ? <FiMinus /> : <FiPlus />}</span>
           </Wrap>
           {clicked === 3 ? (
            <Dropdown className="D6_FE_DD">
 

            {newEvent?<Grid  style={{textAlign:'-webkit-center', }} item sm={12} xs={12}>
                                        
                                     <Grid container>
                                       <Grid item xs={6} style={{placeSelf:'center'}} sm={6}>
                                         Event Title
                                       </Grid>
                                       <Grid item style={{textAlign:'start'}} xs={6} sm={6}>
            
                                       <TextField 
                    id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Event Title" value={data.title} onChange={(e)=>setData({...data,title:e.target.value})} variant="outlined" />
                                       </Grid>
                                       </Grid> 
                                       <Grid container>
                                       <Grid item xs={6} style={{placeSelf:'center'}} sm={6}>
                                         Event Image
                                       </Grid>
                                       <Grid item style={{textAlign:'start'}} xs={6} sm={6}>
            
                                       <div  style={{ display:'flex',justifyContent:'space-around' , backgroundColor: '#52B9D1',borderRadius:'30px',height:'40%',width:'56%',marginTop:30,paddingTop:'5px',textAlign:'center',paddingBottom:'5px'}}>
                              <label  for={`fileNew`} style={{fontSize:'small',fontWeight:'bold' ,width:'100%',color: 'white',cursor:'pointer',alignSelf:'center'}} >{data.image?'Uploaded':processing?'Saving':`Upload Image`}</label>
                              </div>
            
                              <input type={"file" } id={`fileNew`} style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>ImageCover1(e.target.files[0])}/><br />
                   
                                       </Grid>
                                       </Grid> 
                                   
            
                                       <Grid container>
                                       <Grid item xs={6} style={{placeSelf:'center'}} sm={6}>
                                        Event Date
                                       </Grid>
                                       
                                       <Grid item style={{textAlign:'start'}} xs={6} sm={6}>
            
                                       <TextField 
                    id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Event Date" value={data.eventDate} onChange={(e)=>setData({...data,eventDate:e.target.value})} variant="outlined" />
                                       </Grid>
                                       </Grid>  
                                       <Grid container>
                                       <Grid item xs={6} style={{placeSelf:'center'}} sm={6}>
                                        Event Description
                                       </Grid>
                                       
                                       <Grid item style={{textAlign:'start'}} xs={6} sm={6}>
            
                                       <TextField 
                    id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Event Description" value={data.description} onChange={(e)=>setData({...data,description:e.target.value})} variant="outlined" />
                                       </Grid>
                                       </Grid>  
            
                                    
            
                           
              
            </Grid>:null}
            <Grid  style={{textAlign:'-webkit-center', }} item sm={12} xs={12}>
                                        <Chip onClick={()=>saveNewEvent()} label={newEvent&&!processing?'save':newEvent&&processing?'processing':'ADD NEW EVENT'} style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
                    marginBottom: '20px'}}  />
                     {newEvent?<Chip onClick={()=>NewEvent(!newEvent)} label='Cancel' style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',marginLeft:30,
                    marginBottom: '20px'}}  />:null}
            </Grid>
                  <Grid  style={{textAlign:'-webkit-center', }} item sm={12} xs={12}>
            
            
            
                   
                   
                    <Grid  container sm={12} xs={12}>
            
                    <Grid  style={{alignSelf:'center'}} item sm={12} xs={12}>
              
                     <TextField 
                    id="outlined-basic" style={{width:'70%',marginTop:20}} label="Search Events here" onChange={(e)=>console.log(e.target.value)} variant="outlined" />
               
                      </Grid>
                    {(eventData).sort((a,b)=>a.id<b.id?-1:1).map((val1,idx)=>{
              return(
                    <Grid key={idx} style={{borderStyle: 'solid',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    borderRadius: '20px',maxWidth:'100%',margin:10}} container sm={12} xs={12}>
                             <Grid item style={{alignSelf:'center'}} sm={6} xs={4}>
                               
                    <Typography>{val1.urlName}</Typography>
                    </Grid>
            
                    <Grid container style={{textAlign:'start',alignItems:'center'}} sm={6} xs={8}>
                      <Grid item sm={6} xs={12}>
                    <FormGroup  className='form_switch' >
                  <FormControlLabel control={<Switch  checked={(checkedS&&(active===(val1.id+val1.active.toString()))?checked:val1.active)}  onChange={(e)=>changeSwitch(e.target.checked,val1)} />} label="Active" />
                </FormGroup>
                    </Grid>
                  {(active==(val1.id+val1.active.toString())&&checkedS)? <Grid item sm={6} xs={12}>
                    <Chip onClick={()=>Edit(val1,val1.id+val1.active.toString(),val1.active.toString())}  label={edit&&active===(val1.id+val1.active.toString())&&processing?'Saving':'Save' } style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
                    marginBottom: '20px'}}  />
                       <Chip label="Cancel"  onClick={()=>Cancel(val1.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
              
                    </Grid>:null}
                    </Grid>
            
            
            
            
            
                    <Grid  container sm={12} xs={12}>
              
              <Grid  style={{alignSelf:'center'}} item sm={6} xs={12}>
              {edit&&active===(val1.id+val1.title)?
                     <TextField 
                    id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />:
                      <Typography>{val1.title}</Typography>}
               
                      </Grid>
              
                     
                      
                      <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
                      <Chip onClick={()=>Edit(val1,val1.id+val1.title,val1.title)}  label={edit&&active===(val1.id+val1.title)&&!processing?"Save":edit&&active===(val1.id+val1.title)&&processing?'Saving':'Edit Blog Heading' } style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
                    marginBottom: '20px'}}  />
                      {edit&&active===(val1.id+val1.title)? <Chip label="Cancel"  onClick={()=>Cancel(val1.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
                     :null }
                      
                      </Grid>
                      </Grid>
            
                      <Grid  container sm={12} xs={12}>
              
              <Grid  style={{alignSelf:'center'}} item sm={6} xs={12}>
              {edit&&active===(val1.id+val1.date)?
                     <TextField 
                    id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />:
                      <Typography>{val1.date}</Typography>}
               
                      </Grid>
              
                     
                      
                      <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
                      <Chip onClick={()=>Edit(val1,val1.id+val1.date,val1.date)}  label={edit&&active===(val1.id+val1.date)&&!processing?"Save":edit&&active===(val1.id+val1.date)&&processing?'Saving':'Edit Event Date' } style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
                    marginBottom: '20px'}}  />
                      {edit&&active===(val1.id+val1.date)? <Chip label="Cancel"  onClick={()=>Cancel(val1.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
                     :null }
                      
                      </Grid>
                      </Grid>
            
                      <Grid  container sm={12} xs={12}>
              
              <Grid  style={{alignSelf:'center'}} item sm={6} xs={12}>
              {edit&&active===(val1.id+val1.description)?
                     <TextField 
                    id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />:
                      <Typography>{val1.description}</Typography>}
               
                      </Grid>
              
                     
                      
                      <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
                      <Chip onClick={()=>Edit(val1,val1.id+val1.description,val1.description)}  label={edit&&active===(val1.id+val1.description)&&!processing?"Save":edit&&active===(val1.id+val1.description)&&processing?'Saving':'Edit Description' } style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
                    marginBottom: '20px'}}  />
                      {edit&&active===(val1.id+val1.description)? <Chip label="Cancel"  onClick={()=>Cancel(val1.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
                     :null }
                      
                      </Grid>
                      </Grid>
            
            
                 
            
            
            
                    <Grid container className='img_grid'  sm={12} xs={12}>
                    <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
                    <Card sx={{ maxWidth: 345 }}>
            <CardActionArea>
            <CardMedia
            component="img"
            height="240"
            image={image&&active===(val1.id+'img')?URL.createObjectURL(image):(val1.image)}
            alt="green iguana"
            />
            
            </CardActionArea>
            </Card>
             
                    </Grid>
                    <Grid style={{alignSelf:'center',paddingLeft:7}} container sm={6} xs={12}>
            
            
            <Grid style={{alignSelf:'center',marginTop:'20px',marginBottom: '20px'}} item sm={6} xs={12}>
            
            <div  onClick={()=>ImageCover('',val1)} style={{ display:'flex',justifyContent:'space-around' , backgroundColor: '#52B9D1',borderRadius:'30px',height:'40%',paddingTop:'5px',textAlign:'center',paddingBottom:'5px'}}>
                              <label  for={`file${val1.id}`} style={{fontSize:'small',fontWeight:'bold' ,width:'100%',color: 'white',cursor:'pointer'}} >{image&&edit&&active===(val1.id+'img')&&!processing?'Save':image&&edit&&active===(val1.id+'img')&&processing?'Saving':`Edit Image`}</label>
                              </div>
            
                              <input type={edit?'text':"file" } id={`file${val1.id}`} style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>ImageCover(e.target.files[0],val1)}/><br />
                     </Grid> 
            
            
            {edit&&active===(val1.id+'img')?            <Grid style={{alignSelf:'center',marginBottom: '20px'}} item sm={6} xs={12}>
            
            <Chip label="Cancel" onClick={()=>Cancel(val1.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px',cursor:'pointer'}}  />
            </Grid>:null}
            
            </Grid>
                    </Grid>
            
            
            
         
            
            
            
            
            
            
            
            
            
                    </Grid> )})} 
                    </Grid>
                   
                    </Grid >
                              </Dropdown>
           ) : null}
         </>
   
   </Container>
      

   </AccordionSection>
</IconContext.Provider>
   </>
  )
}
