import React from 'react'
import './TBI_PrivacyPolicy_CSS.css';
import {Grid} from '@mui/material';


const TBI_PrivacyPolicy = () => {
    return (
        <div className="TBI_PP_Block">
        <Grid container xs={12} className="TBI_PrivacyPolicy_Block_Grid">
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid1">
                <h3 className="TBI_PrivacyPolicy_Block_MH TBI_PrivacyPolicy_Block_H">
                Privacy Policy
                </h3>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid2">
                <p className="TBI_PrivacyPolicy_Block_P1 TBI_PrivacyPolicy_Block_P">
                Filly Media is committed to respecting your privacy and protecting your data. This Privacy note details how Filly Media (collectively, ” Filly Media ” or “we”, “us”, “our”) collects and uses information/data of our users when they visit our website, www.fillymedia.com.  We also confirm that fms.zopoyo.com, zopoyo.com, click.addtocart.today, tracker.addtocart.today, track.thebrandscorner.com, thebrandscorner.com, track.eliogroups.com, eliogroups.com, track.zopoyo.in, addtocart.today & track.addtocart.today belongs to Filly Media.
<br/>
<br/>
By visiting the website or accessing some parts of the website, you agree to accept the terms and conditions stated in this Privacy Policy. And, by using this website, you express your consent to our use of your personal information in accordance with this Privacy Policy. If you do not accept the terms and conditions, then do not use or access the website.

                 </p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b>1. WHAT INFORMATION WE COLLECT</b><br></br><br />
                We collect certain personally identifiable information from our users which includes:
                <br/>
<br/>
<b>Personal Information</b> - The information that you submit when you register with us. It includes your email address, password, and contact details. We also gather information that you provide us when you contact our support, provide feedback, respond to our communications, or communicate with us via email.
<br/>
<br/>
<b>Technical Information</b> - It includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, operating system, and platform, and other technology on the devices you use to access this website.


                   </p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b>2. HOW WE COLLECT IT? </b><br></br><br />

                We get access to your personal information when
<ul>
    <li>Register on the website/Create an account</li>
<li>Subscribe to our newsletter</li>
<li>Open our emails</li>
<li>Post comments on the site</li>
<li>Link to the site through the social media channels</li>

</ul>
                

</p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b>3. WHY WE COLLECT IT?</b><br></br><br />
                <ul>
    <li>Provide you with a better user experience</li>
<li>Improve the website</li>
<li>For personalized marketing via email or direct marketing offers</li>
<li>Analyze your interests and provide you with additional information about us, our sellers, and partners</li>
<li>To respond to your queries</li>
<li>To analyze marketing and advertising reach and effectiveness</li>
<li>To read user behavior</li>
<li>To send you updates, reminders, support and marketing messages, security alerts, and information and service requests made by you among others.</li>
<li>For promotional activities, events, surveys, and rewards.</li>
<li>To understand your interests better so that we can provide you with relevant information and offers.</li>
<li>For website analytics</li>
<li>For legal obligations and to resolve disputes</li>
</ul>

                   </p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b>4. DISCLOSING THE INFORMATION</b><br></br><br />

                We understand the importance of securing your personal information. We are committed to securing your personal data and will not share it except in the following cases:-
<ul>
<li>With our sellers and partners, so that they can provide you with seamless service and deliver the needful.</li>
<li>For legal compliance or in case of government requests</li>
<li>For identity verification and to detect, prevent, or investigate frauds, crimes, or cyber incidents.</li>
</ul>

<br></br>
We try and ensure that our business partners with whom we share the data, comply with our privacy policy and use the data for the aforementioned purposes. However, their use of personal data and its processing is governed by their Privacy Policy only and is out of the subject of our control.

</p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b>5. WHO DO WE SHARE INFORMATION WITH?</b><br></br><br />
<ul>
<li>Publishers, Developers, Advertisers</li>
<br></br>
<p>Filly Media might share the data collected/received regarding you as mentioned in our privacy policy with our advertiser’s publishers, developers, and our data companies. They might use this data or information to display ads relevant to you, determine the interest areas of the users, and study your site interaction and user behavior to improve their services. 
Such partners’ use of the information we share with them will be governed by their respective privacy policies.</p>
<br></br>
<li>With Affiliates</li>
<br></br>
<p>We may also share your information with affiliates of Filly Media so that we can better provide advertising that is relevant to you.</p>
<br></br>
<li>Sharing information with third parties such as advisors and law enforcement authorities</li>
<br></br>
We might be required to disclose your personal information to law enforcement authorities, as and when necessary to comply with rules and regulations, enforce our terms and conditions, protect our users’ safety and our rights, as well as for fraud protection.
</ul>
                
</p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b>6. INFORMATION SECURITY</b><br></br><br />
                We take stringent measures to ensure the privacy, integrity, and security of your personal data. 
                We constantly strive to protect your information against any fraudulent or unauthorized usage, access, or disclosure.
                <br/>
<br/>
For the same, we use the most secure server for systems where your personal data is collected and stored to prevent any unwarranted access. We adopt the highest security measures for data collection, storage, and processing.
<br/>
<br/>
However, we shall not be responsible for any security breach due to any actions of Third Parties that have your personal information. 
There are many third-party links on the website and we have no control over their privacy policy and security practices.


                   </p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b>7. THIRD-PARTY ADVERTISING</b><br></br><br />
                We may employ third-party advertising companies and/or ad agencies to serve ads when you visit our website. These companies may use information (excluding your name, email address, or phone number) about your visits to this website, and your interaction with our products/services, in order to offer advertisements on this website and other third-party websites about products and services that may be of interest to you. They may also use information about your visits to other websites to offer targeted advertisements for products and services. No personally identifiable information is collected in this process.
                <br/>


                   </p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b>8. LINKS TO OTHER WEBSITES</b><br></br><br />
                There might be affiliates or other third-party websites linked to Filly Media. Personal information that you provide to those sites is not our property. These affiliated sites have their separate and independent privacy policies and we encourage you to read their privacy policies when you visit them. Offer County does not hold any responsibility or liability for the activities and the content of these third-party sites.
                <br/>

                   </p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b>9. NO WARRANTIES</b><br></br><br />
                Although Filly Media attempts to provide accurate content on the website, it makes no warranty that such content is accurate or suitable for any particular purpose will meet your requirements, or will be available on an uninterrupted, timely, secure, or error-free basis.
                <br/>

                   </p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b>10. SPAM AND SALE OF INFORMATION</b><br></br><br />
                We despise spam. We will never do something that we hate ourselves. Thus, you can rest assured that we will not spam your mailboxes with unsolicited emails, and we will never sell your information to other parties.
                <br/>

                   </p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b>11. LIMITATION OF LIABILITY</b><br></br><br />
                In no event will Filly Media be liable for any damages, including without limitation, incidental, special, consequential, or punitive damages, whether under a contract, tort, or any other theory of liability, arising in connection with any party’s use of the website or in connection with any failure of performance, error, omission, interruption, defect, delay in operation or transmission, computer virus, line system failure, loss of data, or loss of use related to this website or any website operated by any third party or any contents of this website or any other website, even if Filly Media is aware of the possibility of such damages?
                <br/>

                   </p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b>12. INDEMNIFICATION</b><br></br><br />
                You agree to indemnify, defend, and hold Filly Media harmless from and against all expenses, claims, losses, damages, and costs, including reasonable attorney’s fees, arising out of or relating to any misuse by the User of the content and services provided on the website.
                <br/>

                   </p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b>13. DISCLAIMER</b><br></br><br />
                The information contained on our website has been obtained from sources believed to be reliable. Filly Media disclaims all warranties as to the accuracy, completeness of the materials, or the reliability of any advice, statement, opinion, or other information distributed through the website. You agree that any reliance on any such statement, opinion, advice, or information shall be at your sole risk and shall not be held against Filly Media for any legal or personal purposes. Filly Media reserves the right, in its sole discretion, to correct any errors or omissions in any portion of the Website. Filly Media may change or discontinue any aspect of its website at any time, including, its content described in the website without notice.
                <br/>

                   </p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b>14. CHANGES TO THIS PRIVACY POLICY</b><br></br><br />
                We aim to meet the highest of standards. We reserve the right to amend the terms of service mentioned in this Privacy Policy with or without prior notice. However, if you do not agree to the changes, you may discontinue accessing our website. Hence, check this page at regular intervals for the latest changes and updates.
                <br/>

                   </p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b>15. CONTACT US</b><br></br><br />
                If you have any queries regarding our privacy policy or any questions about our practices, you can contact us at contact@fillymedia.com
                <br/>

                   </p>
            </Grid>
        
       
       </Grid> </div>

    

    )
}

export default TBI_PrivacyPolicy
