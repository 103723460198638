import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography ,Grid,Paper} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {useCookies} from 'react-cookie'

import CssBaseline from '@mui/material/CssBaseline'
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Logo from '../../images/FMLogo.png'
import MenuIcon from '@mui/icons-material/Menu';

import './admin.css'
import Accordion_Home from './home';
import D6_FE_AccountBox from './Login/D6_FE_AccountBox';
import Accordion_Contact from './contact';
import Accordion_Blogs from './blogsAdmin';
import EventsAdmin from './eventsAdmin';
import PressAdmin from './pressAdmin';
export default function Admin(props) {

  const[auth,setAuth]=useCookies(['auth-fm'])

  const reloadFxn=()=>{
    props.reloadFxn()
  }
  const HomeData=props.homeData
  const ContactData=props.contactData
  const blogData=props.blogData
  const eventData=props.eventData
  const pressData=props.pressData



  const [state, setState] = React.useState({
 
    left: false,
   
  });
  const [screen,setScreen]=React.useState('Admin')
  const [anchor,setAnchor]=React.useState('left')

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
     
      <Divider />
      <List>
        {['Home', 'Contact Us', 'Blogs','Events','Press'].map((text, index) => (
          <ListItem key={text} onClick={()=>setScreen(text)} disablePadding>
            <ListItemButton>
             
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>

{auth&&auth['auth-fm']?  <React.Fragment >
          <CssBaseline />
      <AppBar
        position="fixed" 
        className="appbarMain"
       style={{top:120}}
      >
            <Toolbar>
            <div style={{display:'flex',flexDirection:'row'}}>
              <div >
            <IconButton
             onClick={()=>setState({...state,left:!state.left})}
                color="inherit"
                aria-label="open drawer"
                
                edge="start"
              >
                <MenuIcon  />
              </IconButton>
              </div>
              <div style={{alignSelf:'center'}} >
              <Typography variant="h6" noWrap>
                {screen}
              </Typography>
              </div>
              </div>
        </Toolbar>
      </AppBar>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}

          >

<Grid container  >
            <Grid style={{textAlign:'center'}} item xs='12'>
            <img src={Logo} style={{maxHeight:'90px',maxWidth:'90px',alignItems:'center', marginRight:'auto',}} />
              </Grid>
              <Grid  style={{textAlign:'center'}} item xs='8'>
              <h1 style={{marginTop:'10px',marginRight:'auto'}}>Hey<b style={{color:'#52B9D1'}}> fillymedia</b></h1>
              </Grid>

            
          <Grid item style={{alignSelf:'center',textAlign:'center'}} xs='4'>
          <IconButton               onClick={()=>setState({...state,left:!state.left})}
>
            {state.left ? <ChevronLeftIcon style={{fontSize: 40}} /> : <ChevronRightIcon />}
          </IconButton>

              </Grid>

            </Grid>
                   
            {list(anchor)}
          </Drawer>
          <main
        
      >
        <div  />
        
    {screen==='Home'?
    < Accordion_Home HomeData={HomeData} reloadFxn={reloadFxn} /> 
    :screen==='Contact Us'?<Accordion_Contact ContactData={ContactData} reloadFxn={reloadFxn} /> :
    screen==='Blogs'?<Accordion_Blogs blogData={blogData} ContactData={ContactData} reloadFxn={reloadFxn} />
    :
    screen==='Events'?<EventsAdmin  ContactData={ContactData} eventData={eventData} reloadFxn={reloadFxn}/>
    :
    screen==='Press'?<PressAdmin  ContactData={ContactData} pressData={pressData} reloadFxn={reloadFxn}/>:<div>
      <Grid style={{textAlign: '-webkit-center',marginTop:'235px'}} container>
      <Grid item    sm={3} xs={6}>
    <Paper onClick={()=>setScreen('Home')} className='dash_sub'>
        Home
        </Paper>
        </Grid>
        <Grid item   sm={3} xs={6}>
    <Paper onClick={()=>setScreen('Contact Us')}  className='dash_sub'>
        Contact Us
        </Paper>
        </Grid>
        <Grid item   sm={3} xs={6}>
    <Paper onClick={()=>setScreen('Blogs')}  className='dash_sub'>
        Blogs
        </Paper>
        </Grid>
        <Grid item   sm={3} xs={6}>
    <Paper onClick={()=>setScreen('Events')}  className='dash_sub'>
        Events
        </Paper>
        </Grid>
        <Grid item   sm={3} xs={6}>
    <Paper onClick={()=>setScreen('Press')}  className='dash_sub'>
        Press
        </Paper>
        </Grid>
   
  
        </Grid>
    </div>}
   
   

      </main>
        </React.Fragment>:<D6_FE_AccountBox />}
     
      
      
    </div>
  );
}
