
import Card from '@mui/material/Card';

import {Box, CardActionArea, CardContent, CardMedia, CircularProgress, Container,Grid, Typography} from "@mui/material";import React from 'react'
import './events.css'
export default function Events(props) {
  const eventData=props.eventData
  const ContactData=props.contactData


  if(eventData&&eventData[0]&&ContactData&&ContactData[0]){
    return (
      <div>
                {ContactData.filter((val)=>val.category.includes('BackgroundImageEvents')).sort((a,b)=>a.id<b.id?-1:1).map((val1,idx)=>
<div key={idx} className='event_bg' style={{backgroundImage:`linear-gradient(rgb(99,192,224,0.7),rgb(0,0,0,0.9)),url(${(val1.value)})` }}>
{ContactData.filter((val)=>val.category.includes('BackgroundImageHeadingEvents')).sort((a,b)=>a.id<b.id?-1:1).map((val1,idx)=>
          <h2 key={idx} className="heading2" style={{paddingTop:'45vh'}} >{val1.value}</h2>
)}
          </div>)}
          <Container>
         
             
                  <Grid container style={{paddingBottom:40}} sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography style={{paddingBottom:10}} className='head_WCU'>
  UPCOMING EVENTS
      </Typography>
    </Grid>
    {eventData.filter((val,idx)=>val.active===true).sort((a,b)=>a.id>b.id?1:-1).map((val1,idx)=>
  <Grid key={idx}  item lg={6} sm={12} md={6} xs={12} className="Dec6_Industries_B2B_Grid1"  >
    <Container className="Dec6_Industries_B2B_Cont Dec6_Industries_B2B_Cont1">
    <Card sx={{ maxWidth: '100%' }}>
<CardActionArea>
<CardMedia
component="img"
height="240"
image={val1.image}
alt="green iguana"
/>
<CardContent className='card_color'>

< Typography  gutterBottom className='card_typE' variant="h5" component="div">
{val1.title}
</Typography>

<Typography  className='card_text1E'  variant="body2" color="text.secondary">
Date: {val1.date}
</Typography><br/>


<Typography  className='card_text2E'  variant="body2" color="text.secondary">
{val1.description}</Typography>

</CardContent>
</CardActionArea>
</Card>
    </Container>
</Grid>)  }
  </Grid> 
  
  
  {/* <Grid container sm={12} style={{paddingBottom:40}} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography style={{paddingBottom:10}} className='head_WCU'>
      NEWS, AWARDS AND MEDIA RESOURCES
      </Typography>
    </Grid>
    <Grid  item lg={4} sm={4} md={4} xs={6}  className="Dec6_Industries_B2B_Grid1"  >
      <Container className="Dec6_Industries_B2B_Cont Dec6_Industries_B2B_Cont1">
      <Card className='ppr_card' sx={{ maxWidth: '100%' }}>
  <CardActionArea>
  <CardMedia
  component="img"
  height="240"
  image={require('../../images/mobileapp.png')}
  alt="green iguana"
  />
  
  </CardActionArea>
  </Card>
      </Container>
  </Grid>
  <Grid  item lg={4} sm={4} md={4} xs={6}  className="Dec6_Industries_B2B_Grid1"  >
      <Container className="Dec6_Industries_B2B_Cont Dec6_Industries_B2B_Cont1">
      <Card className='ppr_card' sx={{ maxWidth: '100%' }}>
  <CardActionArea>
  <CardMedia
  component="img"
  height="240"
  image={require('../../images/mobileapp.png')}
  alt="green iguana"
  />
  
  </CardActionArea>
  </Card>
      </Container>
  </Grid>
  <Grid  item lg={4} sm={4} md={4} xs={6} className="Dec6_Industries_B2B_Grid1"  >
      <Container className="Dec6_Industries_B2B_Cont Dec6_Industries_B2B_Cont1">
      <Card className='ppr_card' sx={{ maxWidth: '100%' }}>
  <CardActionArea>
  <CardMedia
  component="img"
  height="240"
  image={require('../../images/mobileapp.png')}
  alt="green iguana"
  />
  
  </CardActionArea>
  </Card>
      </Container>
  </Grid>
  </Grid>  */}
          </Container>
      </div>
    )
  }
  else{
    return(
    <Box sx={{ display: 'flex' }} className="spinner">
    <CircularProgress style={{width:70,height:70}} />
  </Box>)
  }

}
