import { Grid ,Typography} from "@mui/material";
import React from "react";
import { About_Us } from "../about_us/about_us";
import Testi_Crousel from "../testimonials/testi_crousel";
import { Why_Choose_Us } from "../why_choose_us/why_choose";
// import { HomeData as HomeData } from "../homepageData";

import AnimatedNumber from "animated-number-react";
import './D6_SL_SC_CSS.css'


import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Helmet } from "react-helmet";


export default function D6_SL_SCarousel(props) {
  const HomeData=props.homeData
  const evetData=props.eventData


 const formatValue = (value) => parseInt(value);

   //#endregion - end of - number counter animation
   
   if(HomeData&&HomeData[0]&&evetData&&evetData[0]){
  return (

    
    <>

<Helmet>
        {/* <!-- HTML Meta Tags --> */}
 
        <title>Home | FillyMedia</title>
        {HomeData.filter((val)=>val.category.includes('meta-description')).sort((a,b)=>a.id<b.id?-1:1).map((valB,idx)=>{
  return(
        <meta
          name="description"
          content={valB.value}
        />)})} 

{HomeData.filter((val)=>val.category.includes('meta-keywords')).sort((a,b)=>a.id<b.id?-1:1).map((valB,idx)=>{
  return(
    <meta name="keywords" content={valB.value} />
    )})} 

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Home | FillyMedia" />
        {HomeData.filter((val)=>val.category.includes('meta-description')).sort((a,b)=>a.id<b.id?-1:1).map((valB,idx)=>{
  return(
    <meta
    itemprop="description"
    content={valB.value}
  />)})} 
       
        
    

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://fillymedia.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Home | FillyMedia" />
        {HomeData.filter((val)=>val.category.includes('meta-description')).sort((a,b)=>a.id<b.id?-1:1).map((valB,idx)=>{
  return(
    <meta
          property="og:description"
          content={valB.value}
        />)})} 
        
      
      

      
       
      </Helmet>

{HomeData.filter((val)=>val.category.includes('BackgroundImageHome')).sort((a,b)=>a.id<b.id?-1:1).map((val1,idx)=>{
  return(
    <div className="D6_SL_SC_Slider_Div1" style={{backgroundImage:`linear-gradient(rgb(99,192,224,0.7),rgb(0,0,0,0.5)),url(${(val1.value)})` }}>
    {HomeData.filter((val)=>val.category.includes('BackgroundImageText')).sort((a,b)=>a.id<b.id?-1:1).map((val,idx)=>{
      return(
      <h2 className="heading2" key={idx} >{val.value}</h2>
    )})}        
    
         
        </div>
)})} 

     
    <Grid container  className="count_container"  >
    {HomeData.filter((val)=>val.category.includes('CountText')).sort((a,b)=>a.id<b.id?-1:1).map((val1,idx)=>{
  return(
    <Grid key={idx} container sm={3}  className='count_display' xs={6}>
    <Grid container>
      <Grid item sm={12} xs={12}>
<Typography style={{fontWeight:'600'}}>
{val1.value}
</Typography>

</Grid>
<Grid item  sm={12} xs={12}>

{/* <span id={`count${idx+1}`} class="display-4"></span> */}

{HomeData.filter((val)=>(val.category.includes('CountNumber')&&val.parent.toString().includes(val1.id))).sort((a,b)=>a.id<b.id?-1:1).map((val,idx)=>{
  return(
    <span key={idx} >
<AnimatedNumber
          value={val.value}
          duration={2500}
          formatValue={formatValue}
          easing={'linear'}
        />
   +</span>
)})}   

</Grid>

  </Grid>
  </Grid>
)})}  
    </Grid>
    <Why_Choose_Us HomeData={HomeData} />
    <About_Us HomeData={HomeData} eventData={evetData} />
    <Testi_Crousel HomeData={HomeData} />
    </>
  );}
  else{
    return(
    <Box sx={{ display: 'flex' }} className="spinner">
    <CircularProgress style={{width:70,height:70}} />
  </Box>)
  }
}