import React from 'react'
import Button from '@mui/material/Button';
import './D6_Form.css'

import {Container,Paper} from '@mui/material'

import ReactPhoneInput from "react-phone-input-2";
 import 'react-phone-input-2/lib/style.css' 
 import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';



 

const  D6_Form =()=> {
  const ref=React.useRef()

  const [data,setData]=React.useState({
    name:'',
    email:'',
    phone:'',
    message:'',
    company:''

})
const [processing,setProcessing]=React.useState(false)
const [submitted,setSubmitted]=React.useState(false)
const handleSubmit=()=>{
  setProcessing(true)
    const formData=new FormData
    formData.append('name',data.name)
    formData.append('email',data.email)
    formData.append('phonenumber',data.phone)
    formData.append('message',data.message)
    formData.append('company',data.company)

    fetch('https://d6apicluster.herokuapp.com/mail-filly'
    ,
    {
    
      method:'POST',
    
      body:formData
      })
    
      .then(res=>res.json())
      .then((resp)=>MailRes(resp.detail))
    
    
      .catch(error=>console.log(error))
  
  }

    const MailRes=(val)=>{
      setProcessing(false)
setSubmitted(true)
setData({
...data,name:'',email:'',phone:'',message:'',company:''
})
setSubmitted(()=>setTimeout(() => setSubmitted(false), 10000))   
}
    return (
    <Container className="A_Form1_Container">
    <Paper elevation={0} className="A_Form1_Cont_Paper">
        <div className="A_Form1_Div">

            <div className="Floating_Form1">
            <ValidatorForm
                ref={ref}
                onSubmit={handleSubmit}
            >
              {/* <div className='contact_L'>Let's Talk</div> */}
            <div className="TF_1div">
                <label className="TF_1Label">Name*</label>
                <TextValidator
                    label=""
                    onChange={(e)=>setData({...data,
                      name:e.target.value
                  })}
                  name="name"
                  value={data.name}
                    validators={['required', 'matchRegexp:[A-Za-z]+']}
                    errorMessages={['Name required', 'Invalid name']}  
                />  
            </div>
            <div className="TF_1div">
              <label className="TF_1Label">Email*</label>
              <TextValidator
                      label=""
                      onChange={(e)=>setData({...data,
                        email:e.target.value
                    })}  name="email"
                      value={data.email}
                      validators={['required', 'isEmail']}
                      errorMessages={['Email required', 'Invalid email']}
                  />
            </div>
            <div className="PNDiv_SA">
              <label className="PN_1Label2">Phone Number*</label>
              <ReactPhoneInput className="PN_Input2"
                
                inputProps={{
                  name: 'phonenumber',
                  required: true
            /*      autoFocus: true     */
                }}
                country={'in'}
                value={data.phonenumber}  
                isValid={(value, country) => {
                  if (value.match(/12345/)) {
                    return 'Invalid value: '+value+', '+country.name;
                  } else if (value.match(/1234/)) {
                    return false;
                  }
                  else if (value.match(/1234/)) {
                    return 'Phone number is invalid';
                  }
                   else {
                    return true;
                  }
                }}
                onChange={phonenumber =>setData({...data,phone: phonenumber })}  
              />
            </div>
            <div className="TF_1div">
              <label className="TF_1Label">Company name</label>
              <TextValidator
                      label=""
                      onChange={(e)=>setData({...data,
                        company:e.target.value
                    })}  name="company name"
                      value={data.company}
                    //  validators={['required']}
                    //   errorMessages={['Company name required']}
                  />
            </div>
           
            <div className="TF_1div">
              <div>
                <label className="TF_1Label">Message</label>
              </div>
                
               <TextValidator 
               multiline
               maxRows={2}
               
                        name='message'
                        onChange={(e)=>setData({...data,
                            message:e.target.value
                        })}       value={data.message}
                    /> 
              </div>
              
              {/* <div className="Button_UL"></div> */}

                <Button
                    color="primary"
                    disableElevation
                    variant="contained"
                    className="primary_btn"
                    type="submit"
                    disabled={submitted}
                >
                    {
                    submitted&&!processing?'Submitted':processing?'Submitting':'submit'
                    }
                </Button>       
        </ValidatorForm>
        </div>
    </div>
    </Paper>
    </Container>
  
    )
    }


export default D6_Form
