import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import './testi_crousel.css'
import Slide from '@mui/material/Slide';



const NextArrow = ({ onClick }) => {
  return (
    <div className="nextArrow" onClick={onClick}>
      <BsChevronRight />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="prevArrow" onClick={onClick}>
      <BsChevronLeft />
    </div>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Testi_Crousel(props) {
  const {HomeData}=props
  var settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow onClick />,
    prevArrow: <PrevArrow onClick />
  };
  const [open, setOpen] = React.useState(false);

        const handleClickOpen = () => {
          setOpen(true);
        };
      
        const handleClose = () => {
          setOpen(false);
  };
  return (
    <>
 {HomeData.filter((val)=>val.category.includes('crouselBackgroudHome')).sort((a,b)=>a.id<b.id?-1:1).map((valB,idx)=>{
      return(

        <div className="D6_SL_SC_Slider_Div"  key={idx} style={{backgroundImage:`linear-gradient(rgb(0,0,0,0.7),rgb(0,0,0,0.5)), url(${valB.value})`,backgroundSize:'cover',backgroundPosition:'bottom',backgroundRepeat:"no-repeat"}} >
        <Slider {...settings}>
        {HomeData.filter((val)=>val.category==='crouselAvatar').sort((a,b)=>a.id<b.id?-1:1).map((valA,idx)=>{
      return(
<div key={idx} className="img1">


{/* <img src={Skin} alt="Image1" />     */}
<div className="D6_SC_SliderDiv" >
{HomeData.filter((val)=>val.category.includes('crouselHeading')).sort((a,b)=>a.id<b.id?-1:1).map((val,idx)=>{
      return(
        <h3 key={idx} className="D6_SC_SliderDivH1" >
       {val.value}
            </h3>    )})}        
    
   
    <div className="D6_TS_GDiv_Img" >
  
        <img src={valA.value} alt="Person1" className="D6_TS_GDiv_ImgS"/>
     
              </div>
              {HomeData.filter((val)=>(val.category.includes('crouselAvatarText1')&&val.parent.includes(valA.id))).sort((a,b)=>a.id<b.id?-1:1).map((val,idx)=>{
      return(
        <div key={idx} className='testi_text'>
{val.value}
       </div>        )})}
              
       {HomeData.filter((val)=>(val.category.includes('crouselAvatarText2')&&val.parent.includes(valA.id))).sort((a,b)=>a.id<b.id?-1:1).map((val,idx)=>{
      return(
        <div key={idx} className='testi_text1'>
        {val.value}



        </div>     
         )})}
    
    {HomeData.filter((val)=>(val.category.includes('crouselAvatarText3')&&val.parent.includes(valA.id))).sort((a,b)=>a.id<b.id?-1:1).map((val2,idx2)=>{
      return(
        <div key={idx2} className='testi_text2'>
        {val2.value} 


        </div>     )})}           
              
  
      

     
</div>
</div>    )})}        
          
            
          
      
    
    
    
    
    
            
        
        </Slider>
        </div>
    )})} 

   {  }
    
    </>
  );
}