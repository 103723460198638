import './App.css';
import {Routes,Route,BrowserRouter,Navigate} from "react-router-dom";
import Header from './components/common/header/header';
import Navbar from './components/common/header/navbar';
import D6_SL_SCarousel from './components/homepage/homebg_image/D6_SL_SCarousel';
import ContactUs from './components/contact_us/contact_us';
import D6_SL_AF from './components/common/footer/D6_SL_AF';
import D6_SL_Footer from './components/common/footer/D6_SL_Footer';
import Admin from './components/admin/admin';
import { useState,useEffect } from 'react';
import BlogsSub from './components/blogs/blogsSub';
import BlogsMain from './components/blogs/blogsMain';
import NavbarB from './components/common/header/navbarB';
import { Helmet } from 'react-helmet';
import HeaderB from './components/common/header/headerB';
import Dec6_ScrollNPT from './components/common/STTP/scroll_Top';
import Events from './components/events/events';
import Press from './components/press/press';
import TBI_PrivacyPolicy from './components/common/privacypolicy/TBI_PrivacyPolicy';

// export const apilink='http://127.0.0.1:8000/'
// export const apilink='https://filly-prelive.herokuapp.com/'
export const apilink='https://filly-live.herokuapp.com/'

var val=window.location.pathname
  
var page=val.replace('/blog/','')

function App() {
  const [homeData,setHomeData]=useState()
  const [contactData,setContactData]=useState()
  const [blogData,setBlogData]=useState()

  const [eventData,setEventData]=useState()
  const [pressData,setPressData]=useState()


  const [reload,setReload]=useState(0)
  
const reloadFxn=()=>{
 setReload(reload+1)
}

  useEffect(() => {
    async function fetchApi(){
      fetch(apilink+'home-data',
      { method: 'GET',
      headers:{
        'Content-Type' :'application/json'  
      },})
      .then(res => res.json())
      .then(resp=>setHomeData(resp))
      .catch(err=>console.log(err))


    }
    fetchApi()
  }, [reload])
  useEffect(() => {
    async function fetchApi(){
      fetch(apilink+'contact-data',
      { method: 'GET',
      headers:{
        'Content-Type' :'application/json'  
      },})
      .then(res => res.json())
      .then(resp=>setContactData(resp))
      .catch(err=>console.log(err))


    }
    fetchApi()
  }, [reload])
  useEffect(() => {
    async function fetchApi(){
      fetch(apilink+'blog-data',
      { method: 'GET',
      headers:{
        'Content-Type' :'application/json'  
      },})
      .then(res => res.json())
      .then(resp=>setBlogData(resp))
      .catch(err=>console.log(err))


    }
    fetchApi()
  }, [reload])


  useEffect(() => {
    async function fetchApi(){
      fetch(apilink+'events',
      { method: 'GET',
      headers:{
        'Content-Type' :'application/json'  
      },})
      .then(res => res.json())
      .then(resp=>setEventData(resp.data))
      .catch(err=>console.log(err))


    }
    fetchApi()
  }, [reload])

  
  useEffect(() => {
    async function fetchApi(){
      fetch(apilink+'press',
      { method: 'GET',
      headers:{
        'Content-Type' :'application/json'  
      },})
      .then(res => res.json())
      .then(resp=>setPressData(resp.data))
      .catch(err=>console.log(err))


    }
    fetchApi()
  }, [reload])

  if(val.toLocaleLowerCase().includes('/blog/')){
    return(<>
   <BrowserRouter> 
   <Dec6_ScrollNPT />
     <HeaderB eventData={eventData} pressData={pressData}/>
      {/* <NavbarB/> */}
      <BlogsSub page={page} reloadFxn={reloadFxn} contactData={contactData} blogData={blogData}/>
      <D6_SL_AF />
         <D6_SL_Footer />
         </BrowserRouter>
      </>
    )
  }
  else{
  return (
    <BrowserRouter> 
      <Dec6_ScrollNPT />
    {homeData&&homeData[0]&&contactData&&contactData[0]?<Header eventData={eventData} pressData={pressData}/>:null}
    {/* {window.location.pathname!=='/fm-gate'&&homeData&&homeData[0]?<NavbarB/>:null} */}
 
    <Routes>
             <Route  path="/" element={<D6_SL_SCarousel homeData={homeData}  eventData={eventData}/>}  /> 
            <Route  path="/contactus" element={<ContactUs  contactData={contactData}/>} /> 
            <Route  path="/blog" element={<BlogsMain blogData={blogData} contactData={contactData}/>} /> 
            <Route  path="/events" element={<Events eventData={eventData} contactData={contactData}/>} /> 
            <Route  path="/press" element={<Press contactData={contactData} pressData={pressData}/>} /> 
            <Route  path="/privacy_policy" element={<TBI_PrivacyPolicy/>} /> 
            <Route  path="/fm-gate" element={<Admin  reloadFxn={reloadFxn} blogData={blogData} homeData={homeData} contactData={contactData} eventData={eventData} pressData={pressData}/>} /> 

{/* <Navigate to='/' /> */}
<Route path="*" element={<Navigate to ="/" />}/>
         </Routes>
        {window.location.pathname!=='/fm-gate'&&homeData&&homeData[0]?<><D6_SL_AF />
         <D6_SL_Footer /></> :null}

          </BrowserRouter>
  
  );}
}

export default App;
