import React from 'react';

import Grid from '@mui/material/Grid';
import './header.css'

function NavbarB(props) {
  const {eventData,pressData}=props

   
        return(
            <>
           







<nav className='D6_SL_Navbar_Items1'>
<Grid justifyContent={'center'} alignContent={'center'} className='gird_nav' container>
<Grid item>
<div className={window.location.pathname==='/' ? "D6_SL_Navbar_Link2" : "D6_SL_Navbar_Link1"}   onClick={()=>window.location.href='/'}   >
                              HOME
                            </div>
</Grid>
<Grid item>
<div className={window.location.pathname==='/contactus' ? "D6_SL_Navbar_Link2" : "D6_SL_Navbar_Link1"}  onClick={()=>window.location.href='/contactus'}   >
                              CONTACT US
                            </div>
</Grid>
<Grid item>
<div className={window.location.pathname==='/blog' ? "D6_SL_Navbar_Link2" : "D6_SL_Navbar_Link1"}   onClick={()=>window.location.href='/blog'} >
                              BLOG
                            </div>
</Grid>
{eventData&&eventData[0]&&eventData.filter(val=>val.active===true).length>0?<Grid item>
<div className={ "D6_SL_Navbar_Link1"}   onClick={()=>window.location.href='/events'}  >
EVENTS
                            </div>
</Grid>:null}
{pressData&&pressData[0]&&pressData.filter(val=>val.active===true).length>0?<Grid item>
<div className={ "D6_SL_Navbar_Link1"}   onClick={()=>window.location.href='/PRESS'}  >
PRESS
                            </div>
</Grid>:null}
<Grid item>
<div className={ "D6_SL_Navbar_Link1"}   onClick={()=>window.open("https://affiliate.fmstracking.online/",'_blank')}  >
PUBLISHER LOGIN
                            </div>
</Grid>
    </Grid>                  
            </nav>   
            </>
        );
    }
    
    export default NavbarB;