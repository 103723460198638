import React, { useState,useEffect } from 'react';
import styled from 'styled-components';
import { IconContext } from 'react-icons';
import { FiPlus, FiMinus } from 'react-icons/fi';
import './admin.css'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, Typography } from '@mui/material';
import {Grid} from "@mui/material";
import Chip from '@mui/material/Chip';
import { Alert, AlertTitle } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { apilink } from '../../App';
import TextField from '@mui/material/TextField';
import { ContactData as ConData } from '../contact_us/contactpageData';

const AccordionSection = styled.div`
margin-top:30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
/*  position: relative;  */
  height: 170vh;
  height: 100%;
 /* background: #fff;  */
  background: white;  
  z-index: 4;
  /*
  @media (max-width: 1200px) {
        height: 180vh ;
  }
  @media (max-width: 750px) {
        height: 110vh ;
  }
  @media (max-width: 660px) {
        height: 120vh ;
  }
  @media (max-width: 469px) {
        height: 135vh ;
  }
  @media (max-width: 430px) {
        height: 146vh ;
  }
  @media (max-width: 400px) {
        height: 154vh ;
  }
  @media (max-width: 350px) {
        height: 167vh ;
  }  */
`;

const Container = styled.div`
/*  position: absolute; */
  width: 100%;
  top: 30%;
/*  box-shadow: 2px 10px 35px 1px rgba(153, 153, 153, 0.3);
  box-shadow: 2px 10px 35px 1px rgba(153, 153, 153, 0.3);*/
`;

const Wrap = styled.div`
  background: #272727;
  background: white;
  color: #fff;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  cursor: pointer;
  h1 {
    padding: 2rem;
    font-size: 1.3rem;
    text-align: left;
  }
  span {
    margin-right: 1.5rem;
  }
  @media (max-width: 750px) {
    h1 {
    font-size: 1.05rem;
    padding: 1rem;
    }
  }
  
`;

const Dropdown = styled.div`
  background: #1c1c1c;
  background: white;
  color: white;
  color: black;
  width: 100%;
  height: 130px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
 /* align-items: center; */
  border-bottom: 1px solid white;
  border-bottom: 1px solid var(--redcolor);
  border-top: 1px solid white;
  border-top: 1px solid var(--redcolor);
  p {
    padding: 2rem;
    padding: 0.5rem 2rem;
    font-size: 1.1rem;
  }
  @media (max-width: 750px) {
    p {
    font-size: 0.9rem;
    padding: 0.2rem 1rem;
    }
  }
  /*
  @media (max-width: 1200px) {
        height: 160px ;
    p {
    padding: 0rem 1rem;
    font-size: 1rem;
    }
  }
  @media (max-width: 750px) {
        height: 190px ;
  }
  @media (max-width: 652px) {
        height: 210px ;
  }
  @media (max-width: 600px) {
        height: 240px ;
  }
  @media (max-width: 540px) {
        height: 270px ;
  }
  @media (max-width: 480px) {
        height: 300px ;
  }
  @media (max-width: 440px) {
        height: 350px ;
  }
  @media (max-width: 400px) {
        height: 400px ;
  }
  @media (max-width: 357px) {
        height: 470px ;
  }   */
`;



const Accordion_Contact = (props) => {
   
   
  const { ContactData }=props
const [image,setImage]=useState('')
const [error,setError]=useState('')
const [edit,setEdit]=useState('')
const [open,setOpen]=useState(false)
const [processing,setProcessing]=useState(false)
const[active,setActive]=useState('')
const [text,setText]=useState('')
const [newV,setNewV]=useState(false)
const [newV1,setNewV1]=useState(false)
const [open1,setOpen1]=useState(false)

const handleClose=()=>{
  setOpen(false)
  setOpen1(false)

}


  const [clicked, setClicked] = useState(false);

  const toggle = index => {
    if (clicked === index) {
      return setClicked(null);
    }

    setClicked(index);
  };


  const ImageCover=(img,val)=>{
    
    

    if(!edit&&img){
      setActive(val.id)
      setEdit(true)
      
    if(img&&img.name)
  
      if (img.name.toLowerCase().match(/\.(jpg|jpeg|png)$/))
    {
      
      if(img.size/1048576 <4){
  setImage(img)   
    }
       else{
         setError('File should be <4MB')
         setOpen(true)
       }
     } 
     else{
       setError('Upload jpg|jpeg|png format image')
       setOpen(true)
  
     }}
     if(edit&&!img&&active===val.id){
       Save(val)
     }
     if(edit&&!img&&active!==val.id){
       Cancel()
     }
     if(edit&&img&&active!==val.id){
      Cancel()
    }
  }

  const Cancel=()=>{
    setActive('')
    setImage('')
    setEdit(!edit)
    setText('')
  }
  const Edit=(val)=>{

   

    if(edit&&active===val.id){
     
      Save(val)
    }
    else{
      setActive(val.id)
      setText(val.value)
      setImage('')
      setEdit(!edit)
    }


  }
  const reloadFxn=()=>{
    props.reloadFxn()
  }
  const Save=(val,val1)=>{
    setProcessing(true)
    const formData=new FormData()
    formData.append('value',val.type==='image'&&image?image:text)
    formData.append('id',val.id)
    formData.append('type',val.type)


fetch(apilink+'contact',{
 method:'PUT',
 body:formData
  
}).then(resp=>resp.json())  
.then((res)=>respSave(res))  
  }

  const respSave=(val)=>{
    setError(val.detail)

    setEdit(false)
    setActive('')
    setImage('')
    setText('')
    setProcessing(false)
    setNewV1(false)
setNewV(false)

    if(val.status){

      
  
setOpen1(true)
reloadFxn()
}
if(!val.status){
 
setOpen(true)
}

  }

  const saveNewV=(val)=>{

    if(newV&&val==='meta-description'||newV1&&val==='meta-keywords'){
    setProcessing(true)
    const formData=new FormData()
    formData.append('value',text)
    formData.append('type','text')
    formData.append('category',val)


fetch(apilink+'contact',{
 method:'POST',
 body:formData
  
}).then(resp=>resp.json())  
.then((res)=>respSave(res))  
}else{
  if(val==='meta-description'){
    setNewV(true)
    setNewV1(false)
  }
  else{
    setNewV1(true)

    setNewV(false)
  }
}
  }
  return (
    <>
      <h1 style={{marginTop:'30vh',paddingLeft:'25px'}}>
          Contact Page Sections
      </h1>
      <Snackbar open={open}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert onClose={handleClose} severity="error">
        <AlertTitle>Error!!</AlertTitle>
        {error}
      </Alert>
      </Snackbar>
      <Snackbar open={open1}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert onClose={handleClose} severity="success">
        <AlertTitle>Success!!</AlertTitle>
        {error}
      </Alert>
      </Snackbar>
      <IconContext.Provider className="D6_FE_A_IC" value={{ color: 'var(--bcolor)', size: '25px' }}>
    
      <AccordionSection  className="D6_FE_AS">
       

<Container className="D6_FE_Cont">
         
              <>
                <Wrap className="D6_FE_Wrap" onClick={() => toggle(1)}>
                  <h1 style={{color:'#000',fontWeight:'normal'}}>Background Image</h1>
                  <span>{clicked === 1 ? <FiMinus /> : <FiPlus />}</span>
                </Wrap>
                {clicked === 1 ? (
                  <Dropdown className="D6_FE_DD">
              {ContactData.filter((val)=>val.category.includes('BackgroundImageContact')).sort((a,b)=>a.id<b.id?-1:1).map((val1,idx)=>{
 return(    
       <Grid  key={idx} container className="Dec6_Industries_B2B_Cont Dec6_Industries_B2B_Cont1">
   <Grid style={{textAlign:'-webkit-center'}} item sm={6} xs={12}>
 
    <Card sx={{ maxWidth: 345 }}>
<CardActionArea>
<CardMedia
component="img"
height="240"
image={image?URL.createObjectURL(image):(val1.value)}
alt="green iguana"
/>

</CardActionArea>
</Card>
 
</Grid>
<Grid style={{alignSelf:'center'}} container sm={6} xs={12}>
<Grid style={{alignSelf:'center',marginTop:'20px'}} item sm={6} xs={12}>

{/* <Chip label="Edit Background Image" style={{backgroundColor:'#52B9D1',color:'#ffff'}}  /> */}
<div onClick={()=>{ImageCover('',val1)}} style={{ display:'flex',justifyContent:'space-around' , backgroundColor: '#52B9D1',borderRadius:'30px',height:'40%',paddingTop:'5px',textAlign:'center',paddingBottom:'5px'}}>
                          <label  for="files1" style={{fontSize:'small',fontWeight:'bold' ,width:'100%',color: 'white',cursor:'pointer'}} >{image&&edit&&active===val1.id&&!processing?'Save':image&&edit&&active===val1.id&&processing?'Saving':'Edit Background Image'}</label>
                          </div>

                          <input type={edit?'text':"file" } id="files1" style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>ImageCover(e.target.files[0],val1)}/><br />
                 </Grid> 
    {edit?             <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
  
<Chip label="Cancel" onClick={()=>Cancel(val1.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px',cursor:'pointer'}}  />
</Grid>:null}
</Grid>
    </Grid >
     )})}  
                  </Dropdown>
                ) : null}
              </>
        
        </Container>



        <Container className="D6_FE_Cont">
         
         <>
           <Wrap className="D6_FE_Wrap" onClick={() => toggle(2)}>
             <h1 style={{color:'#000',fontWeight:'normal'}}>Background Image Heading</h1>
             <span>{clicked === 2 ? <FiMinus /> : <FiPlus />}</span>
           </Wrap>
           {clicked === 2 ? (
             <Dropdown className="D6_FE_DD">
              
              {ContactData.filter((val)=>val.category===('BackgroundImageHeading')).sort((a,b)=>a.id<b.id?-1:1).map((val,idx)=>{
      return(
        <Grid key={idx}  container className="Dec6_Industries_B2B_Cont Dec6_Industries_B2B_Cont1">
        <Grid style={{textAlign:'-webkit-center'}} item sm={6} xs={12}>
       
      {edit&&active===val.id?
       <TextField 
      id="outlined-basic" style={{width:'70%'}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />:
        <Typography>{val.value}</Typography>}
        </Grid>
        <Grid style={{alignSelf:'center'}} className='gird_chip' item sm={6} xs={12}>
        <Chip onClick={()=>Edit(val)}  label={edit&&active===val.id&&!processing?"Save":edit&&active===val.id&&processing?'Saving':'Edit Text' }style={{backgroundColor:'#52B9D1',color:'#ffff'}}  />
       {edit&&active===val.id? <Chip label="Cancel"  onClick={()=>Cancel(val.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
       :null }
        </Grid>
        </Grid >
    )})}  
              
  
             </Dropdown>
           ) : null}
         </>
   
   </Container>
   <Container className="D6_FE_Cont">
         
              <>
                <Wrap className="D6_FE_Wrap" onClick={() => toggle(3)}>
                  <h1 style={{color:'#000',fontWeight:'normal'}}>Background Image Text</h1>
                  <span>{clicked === 3 ? <FiMinus /> : <FiPlus />}</span>
                </Wrap>
                {clicked === 3 ? (
                  <Dropdown className="D6_FE_DD">
           {ContactData.filter((val)=>val.category.includes('BackgroundImageContact')).sort((a,b)=>a.id<b.id?-1:1).map((val1,idx)=>{
 return(    
      
        <Grid style={{padding:'12px'}}   container className="Dec6_Industries_B2B_Cont Dec6_Industries_B2B_Cont1">
        {ContactData.filter((val)=>(val.category.includes('BackgroundImageText')&&val.parent.toString().includes(val1.id))).sort((a,b)=>a.id<b.id?-1:1).map((val,idx)=>{
  return(
        <Grid key={idx}  container className="Dec6_Industries_B2B_Cont Dec6_Industries_B2B_Cont1">
        <Grid style={{textAlign:'-webkit-center'}} item sm={6} xs={12}>
       
      {edit&&active===val.id?
       <TextField 
      id="outlined-basic" style={{width:'70%'}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />:
        <Typography>{val.value}</Typography>}
        </Grid>
        <Grid style={{alignSelf:'center'}} className='gird_chip' item sm={6} xs={12}>
        <Chip onClick={()=>Edit(val)}  label={edit&&active===val.id&&!processing?"Save":edit&&active===val.id&&processing?'Saving':'Edit Text' }style={{backgroundColor:'#52B9D1',color:'#ffff'}}  />
       {edit&&active===val.id? <Chip label="Cancel"  onClick={()=>Cancel(val.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
       :null }
        </Grid>
        </Grid >
    )})}  
       
        </Grid >
           )})}  
   
                  </Dropdown>
                ) : null}
              </>
              
        
        </Container>
        <Container className="D6_FE_Cont">
         
         <>
           <Wrap className="D6_FE_Wrap" onClick={() => toggle(4)}>
             <h1 style={{color:'#000',fontWeight:'normal'}}>Meta Tags</h1>
             <span>{clicked === 4 ? <FiMinus /> : <FiPlus />}</span>
           </Wrap>
           {clicked === 4 ? (
             <Dropdown className="D6_FE_DD">
   
 <Grid  style={{textAlign:'-webkit-center',borderBottomStyle:'solid',paddingBottom:15 }} container sm={12} xs={12}>
<Grid style={{alignSelf:'center'}} item sm={12} xs={12}>
<Typography>Meta Description</Typography>
</Grid>
{newV?<Grid style={{alignSelf:'center'}} item sm={12} xs={12}>
<TextField 
 id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />
</Grid>:null}

<Grid  style={{textAlign:'-webkit-center', }} item sm={12} xs={12}>
                       <Chip onClick={()=>saveNewV('meta-description')} label={newV&&!processing?'save':newV&&processing?'processing':' ADD NEW DESCRIPTION'} style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
   marginBottom: '20px'}}  />
    {newV?<Chip onClick={()=>setNewV(!newV)} label='Cancel' style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',marginLeft:30,
   marginBottom: '20px'}}  />:null}
</Grid>
{ContactData.filter((val)=>(val.category==='meta-description')).sort((a,b)=>a.id<b.id?-1:1).map((valB,idx)=>{
 return( 
   <Grid  container key={idx}>
<Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
   {edit&&active===valB.id?
  <TextField 
 id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />:
   <Typography>{valB.value}</Typography>}


   </Grid>
   <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
   <Chip onClick={()=>Edit(valB)}  label={edit&&active===valB.id&&!processing?"Save":edit&&active===valB.id&&processing?'Saving':'Edit Description' } style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
 marginBottom: '20px'}}  />
   {edit&&active===valB.id? <Chip label="Cancel"  onClick={()=>Cancel(valB.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
  :null }
   
   </Grid>
   </Grid>)})} 
 </Grid>
 
 
 
 
 <Grid style={{textAlign:'-webkit-center',paddingBottom:20 }} container sm={12} xs={12}>
<Grid style={{alignSelf:'center'}} item sm={12} xs={12}>
<Typography>Meta Keywords</Typography>
</Grid>
{newV1?<Grid style={{alignSelf:'center'}} item sm={12} xs={12}>
<TextField 
 id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />
</Grid>:null}

<Grid  style={{textAlign:'-webkit-center', }} item sm={12} xs={12}>
                       <Chip onClick={()=>saveNewV('meta-keywords')} label={newV1&&!processing?'save':newV1&&processing?'processing':'ADD NEW KEYWORDS'} style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
   marginBottom: '20px'}}  />
    {newV1?<Chip onClick={()=>setNewV1(!newV1)} label='Cancel' style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',marginLeft:30,
   marginBottom: '20px'}}  />:null}
</Grid>
{ContactData.filter((val)=>(val.category==='meta-keywords')).sort((a,b)=>a.id<b.id?-1:1).map((valB,idx)=>{
 return( 
   <Grid  container key={idx}>
<Grid style={{alignSelf:'center',textAlign:'-webkit-center',}} item sm={6} xs={12}>
   {edit&&active===valB.id?
  <TextField 
 id="outlined-basic" style={{width:'70%',marginTop:20}} multiline rows={2} label="Text" value={text} onChange={(e)=>setText(e.target.value)} variant="outlined" />:
   <Typography>{valB.value}</Typography>}


   </Grid>
   <Grid style={{alignSelf:'center'}} item sm={6} xs={12}>
   <Chip onClick={()=>Edit(valB)}  label={edit&&active===valB.id&&!processing?"Save":edit&&active===valB.id&&processing?'Saving':'Edit Keywords' } style={{backgroundColor:'#52B9D1',color:'#ffff',marginTop: '20px',
 marginBottom: '20px'}}  />
   {edit&&active===valB.id? <Chip label="Cancel"  onClick={()=>Cancel(valB.id)} style={{backgroundColor:'#52B9D1',color:'#ffff',marginLeft:'30px'}}  />
  :null }
   
   </Grid>
   </Grid>)})} 
 </Grid>
 
  </Dropdown>
           ) : null}
         </>
   
   </Container> 
      </AccordionSection>
    </IconContext.Provider>
  
  {/* <div  dangerouslySetInnerHTML={{ __html: value1}}/> */}
    </>
  );
};

export default Accordion_Contact;