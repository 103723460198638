
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import {Container,Typography,Grid} from "@mui/material";

import './why_choose.css'
// import { HomeData } from '../homepageData';
export const Why_Choose_Us=(props)=>{
  const {HomeData}=props

    return(


      
        <div>

{HomeData.filter((val)=>val.category.includes('WhyChooseHead')).sort((a,b)=>parseInt(a.id)<parseInt(b.id)?-1:1).map((val,idx)=>{
  return(
    <div key={idx}>
    <Container>
    <Grid container textAlign={'center'} alignItems="center"     >
<Grid item sm={12} xs={12}>
<Typography className='head_WCU'>{val.value}</Typography>
</Grid>

{HomeData.filter((val1)=>(val1.category.includes('WhyChooseSubHead')&&val1.parent.includes(val.id.toString()))).sort((a,b)=>a.id<b.id?-1:1).map((val,idx)=>{
  return(
    <Grid key={idx} item  className='sub_WCU' sm={12} xs={12}> {val.value}</Grid>

)})} 
{/* <Grid item  className='sub_WCU' sm={12} xs={12}> Action speak louder than words, so we are here to show the action. We are a performance marketing agency who believes in boasting about our performance campaigns!</Grid> */}
    </Grid>
    </Container>
            <Container>
    <Grid container justify="center" alignItems="center"  className="Dec6_Industries_B2B_GridS"   >
           
    {HomeData.filter((val1)=>(val1.category.includes('WhyChooseCardMedia')&&val1.parent.includes(val.id.toString()))).sort((a,b)=>a.id<b.id?-1:1).map((val2,idx)=>{
  return(
    <Grid key={idx} item lg={4} sm={12} md={4} xs={12} className="Dec6_Industries_B2B_Grid1"  >
    <Container className="Dec6_Industries_B2B_Cont Dec6_Industries_B2B_Cont1">
    <Card sx={{ maxWidth: 345 }}>
<CardActionArea>
<CardMedia
component="img"
height="240"
image={val2.value}
alt="green iguana"
/>
<CardContent className='card_color'>
{HomeData.filter((val1)=>(val1.category.includes('CardContentHead')&&val1.parent.includes(val.id.toString())&&val1.parent2.includes(val2.id))).sort((a,b)=>a.id<b.id?-1:1).map((val3,idx)=>{
  return(
< Typography key={idx} gutterBottom className='card_typ' variant="h5" component="div">
{val3.value}
</Typography>
)})} 


{HomeData.filter((val1)=>(val1.category.includes('CardContentSubHead')&&val1.parent.includes(val.id.toString())&&val1.parent2.includes(val2.id.toString()))).sort((a,b)=>(a.id)<(b.id)?-1:1).map((val3,idx)=>{
  return(
<Typography  key={idx} className='card_text'  variant="body2" color="text.secondary">
{val3.value}
</Typography>
)})} 

</CardContent>
</CardActionArea>
</Card>
    </Container>
</Grid>
)})} 

           
        
                    
                </Grid>
             
    </Container>
    </div>
)})} 




        </div>
    )
}