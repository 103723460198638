import React from 'react'
import './D6_SL_Footer_CSS.css'

const D6_SL_Footer = () => {
    return (
        <div className="D6_SL_Footer_Items">
            
                <div className="D6_SL_Footer_Items_P_Div">  
                    <p className="D6_SL_Footer_Items_P">
                        &#169;2022 Filly Media
                    </p>
                </div>
        </div>
    )
}

export default D6_SL_Footer
