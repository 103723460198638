import React from 'react'
import './D6_SL_AF_CSS.css';
import {Grid,Container,TextField} from '@mui/material';
import {NavLink} from "react-router-dom";



import './D6_SL_AF_CSS.css'
import D6_DASAC_Form from '../../contact_us/contact_form/D6_DASAC_Form';
import D6_Form from '../../contact_us/contact_form/D6_Form';


const D6_SL_AF = () => {
  
    return (
   <div className="D6_AboveFooter_Container">   

 <Container  style={{maxWidth:1440}} fixed className="D6_AF_Grid_Cont">
 <Grid container justify="center" className="D6_AF_Grid">

        <Grid item md={3} xs={12} className="D6_AF_Grid3">
            <div className="D6_AboveFooter_Item" id="D6_AboveFooter_Item-3">
                <h3 className="D6_AboveFooter_H3">Our Services</h3>
                <ul className="D6_AboveFooter_List3">
                    <li>
                        <NavLink className=""  to="/contactus">
                            MOBILE APP MARKETING
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className=""  to="/contactus">
                        AFFILIATE MARKETING
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className=""  to="/contactus">
                            DESIGN & DEVELOPEMENT
                        </NavLink>
                    </li>
                </ul>
             
            </div>
        </Grid>

        <Grid item md={3} xs={12} className="D6_AF_Grid5">
            <div className="D6_AboveFooter_Item" id="D6_AboveFooter_Item-5">  
                <h3 className="D6_AboveFooter_H1">Head Office (India)</h3>
                <div className="D6_AboveFooter_Item5D1">
                    <p className="D6_AboveFooter_HC1 D6_AboveFooter_HC11">
                    Vipul Business Park, 218, Floor-2, Sohna Rd </p>
                 
                    <p className="D6_AboveFooter_HC1">
                    Sector 48, Gurugram, Haryana 122018, India
                    </p>
                    {/* <div className="D6_AboveFooter_Item" id="D6_AboveFooter_Item-5">   */}
                <h3 className="D6_AboveFooter_H1">U.A.E (Sharjah)</h3>
                <div className="D6_AboveFooter_Item5D1">
                    <p className="D6_AboveFooter_HC1 D6_AboveFooter_HC11">
                    Sharjah Media City, Sharjah, U.A.E
 </p>
                 
                   
                    

              
                {/* </div> */}
            </div>

                
                </div>
            </div>
        </Grid>

        {/* <Grid item md={3} xs={12} className="D6_AF_Grid5">
            <div className="D6_AboveFooter_Item" id="D6_AboveFooter_Item-5">  
                <h3 className="D6_AboveFooter_H1">U.A.E (Sharjah)</h3>
                <div className="D6_AboveFooter_Item5D1">
                    <p className="D6_AboveFooter_HC1 D6_AboveFooter_HC11">
                    Sharjah Media City, Sharjah, U.A.E
 </p>
                 
                   
                    <div className="D6_AboveFooter_Icon1">
                        <i className="fas fa-phone-alt "></i>
                        <a href="tel:+91-124-4941059"className="D6_AboveFooter_Icon_H">
                        +91-124-4941059
                        </a>  
                    </div>
                
                    <div className="D6_AboveFooter_Icon1">
                        <i className="fa fa-envelope"></i>
                        <a href="mailto:contact@fillymedia.com"  className="D6_AboveFooter_Icon_E">
                        contact@fillymedia.com
                        </a>  
                    </div>

              
                </div>
            </div>
        </Grid> */}
        <Grid item md={3} xs={12} className="D6_AF_Grid5">
            <div className="D6_AboveFooter_Item" id="D6_AboveFooter_Item-5">  
                <h3 className="D6_AboveFooter_H1">Reach Us</h3>
                <div className="D6_AboveFooter_Item5D1">
                 
                 


                    <div className="Dec6_AboveFooter_SymbolsList1">
                        <a href="https://www.linkedin.com/company/fillymedia.com"  className="fab fa-linkedin-in circle-icon1 circle-icon-tw aicon"></a>
                        <a href="https://www.facebook.com/FillyMediacom"  className="fab fa-facebook circle-icon1   ">
                      
                      
                        </a>
                        <a href="https://twitter.com/Fillymediacom"  className="fab fa-twitter circle-icon1   ">
</a>
<div  style={{marginTop:20}} className="D6_AboveFooter_Icon1">
                        <i className="fas fa-phone-alt "></i>
                        <a href="tel:+91-124-4941059"className="D6_AboveFooter_Icon_H">
                        +91-124-4941059
                        </a>  
                    </div>
                
                    <div className="D6_AboveFooter_Icon1">
                        <i className="fa fa-envelope"></i>
                        <a href="mailto:contact@fillymedia.com"  className="D6_AboveFooter_Icon_E">
                        contact@fillymedia.com
                        </a>  
                    </div>
                     
                    </div> 
                </div>
            </div>
        </Grid>
        <Grid item md={3} xs={12} className="D6_AF_Grid5">
        <div className="D6_AboveFooter_Item" id="D6_AboveFooter_Item-5">  
                <h3  style={{marginBottom:0}} className="D6_AboveFooter_H1">Quick Links</h3>
              {/* <D6_Form /> */}
              <ul className="D6_AboveFooter_List3">
              <li>
                        <NavLink className=""  to="/blog">
                        BLOG
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className=""  to="/events">
                            EVENTS
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className=""  to="/privacy_policy">
                            PRIVACY POLICY
                        </NavLink>
                    </li>
                  
                 
                </ul>
             
              </div>
        </Grid>
    </Grid>   
</Container>  
</div>
    )
}

export default D6_SL_AF
