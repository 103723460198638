import Card from '@mui/material/Card';

import {Box,Paper, CardActionArea, CardContent, CardMedia, CircularProgress, Container,Grid, Typography} from "@mui/material";import React from 'react'
import './press.css'
export default function Press(props) {
    const {contactData,pressData}=props

    if(pressData&&pressData[0]&&contactData&&contactData[0]){
   
  return (

    <div style={{background:'rgba(151, 151, 151,.17)'}}>
                      {contactData.filter((val)=>val.category.includes('BackgroundImagePress')).sort((a,b)=>a.id<b.id?-1:1).map((val1,idx)=>
  <div key={idx} className='background_press' style={{backgroundImage:`linear-gradient(rgb(0,0,0,0.7),rgb(0,0,0,0.9)),url(${val1.value})` }}>
     {contactData.filter((val)=>val.category.includes('BackgroundImageHeadingPress')).sort((a,b)=>a.id<b.id?-1:1).map((val1,idx)=>
     <h2 key={idx} className="heading2" style={{paddingTop:'25vh'}} >{val1.value}</h2>)}


        </div>)}
        <Container>
            <Grid container >
                
                   <Paper elevation={6} className='ppr_press'>
                   <Grid container style={{height:'100%',alignContent:'center'}} sm={12} xs={12}>
                   {contactData.filter((val)=>val.category.includes('PressImages')).sort((a,b)=>a.id<b.id?-1:1).map((val1,idx)=>

                    <Grid key={idx} item sm={3} xs={6}> 
                        <img src={val1.value} className='img_press' />
                    </Grid>)}
                   
                    </Grid>

                    </Paper> 

                    <Grid container style={{marginTop:'5vh',marginBottom:'5vh'}} sm={12} xs={12}>
                    {pressData.filter((val,idx)=>val.active===true).sort((a,b)=>a.id>b.id?1:-1).map((val1,idx)=>  <Grid item sm={4} xs={12}>
                      <Card sx={{ maxWidth: '100%' }} className='card_press'>
<CardActionArea>
<CardMedia
component="img"
height="85"
image={val1.image}
alt="green iguana"
/>
<CardContent className='card_color'>

< Typography  gutterBottom className='card_headP' variant="h5" component="div">
{val1.title}
</Typography>
<Typography  className='card_textP'  variant="body2" color="text.secondary">
{val1.description}</Typography><br/>


<Typography  onClick={()=>window.open(val1.url,'_blank')} style={{textAlign:'end',paddingRight:'10px', color:'#48a3b8 '}} className='card_text2E1'  variant="body2" color="text.secondary">
Read more</Typography>

</CardContent>
</CardActionArea>
</Card>
                        
                        </Grid>)}  
              
                     
                    </Grid>
                </Grid>
        </Container>
    </div>
  )}
  else{
    return(
    <Box sx={{ display: 'flex' }} className="spinner">
    <CircularProgress style={{width:70,height:70}} />
  </Box>)
  }
}
