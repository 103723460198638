import React,{useState} from 'react';
import {NavLink,Link} from "react-router-dom";

import Grid from '@mui/material/Grid';
import './header.css'

function Navbar(props) {
              
 const {eventData,pressData}=props
   
        return(
            <>
            <div className='D6_SL_Navbar_Items1'>
<Grid justifyContent={'center'} alignContent={'center'} container className='gird_nav'>
<Grid item>
<NavLink className={({ isActive }) => isActive ? "D6_SL_Navbar_Link2" : "D6_SL_Navbar_Link1"}  to="/" onClick={()=>window.location.href='/'}   >
                              HOME
                            </NavLink>
</Grid>
<Grid item>
<NavLink className={({ isActive }) => isActive ? "D6_SL_Navbar_Link2" : "D6_SL_Navbar_Link1"}  to="contactus"   >
                              CONTACT US
                            </NavLink>
</Grid>
<Grid item>
<NavLink className={({ isActive }) => isActive ? "D6_SL_Navbar_Link2" : "D6_SL_Navbar_Link1"}  to="blog"   >
                              BLOG
                            </NavLink>
</Grid>
{eventData&&eventData[0]&&eventData.filter(val=>val.active===true).length>0?<Grid item>
<NavLink className={({ isActive }) => isActive ? "D6_SL_Navbar_Link2" : "D6_SL_Navbar_Link1"}  to="events"   >
                              EVENTS
                            </NavLink>
</Grid>:null}
{pressData&&pressData[0]&&pressData.filter(val=>val.active===true).length>0?<Grid item>

<NavLink className={({ isActive }) => isActive ? "D6_SL_Navbar_Link2" : "D6_SL_Navbar_Link1"}  to="press"   >
                              PRESS
                            </NavLink>
</Grid>:null}
<Grid item>

<div className= "D6_SL_Navbar_Link1"  onClick={()=>window.open("https://affiliate.fmstracking.online/",'_blank')}   >
                            PUBLISHER LOGIN
                            </div>
</Grid>
    </Grid>                  
            </div>








            </>
        );
    }
    
    export default Navbar;